/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import { ContentWrapper, Main } from "gatsby-theme-elements"
import styled from "@emotion/styled"

import SEO from "../components/Seo"

const LoginPage = () => {
  return (
    <Fragment>
      <SEO title="Login" />
      <ContentWrapper>
        <Main>
          <h2>Login Page!</h2>
        </Main>
      </ContentWrapper>
    </Fragment>
  )
}

export default LoginPage
